// ==================================================
//  変数定義
// ==================================================
const breakpointSP = 767;

// ==================================================
//  Function: モバイル判定
// ==================================================
const isMobile = (vw) => {
  if (breakpointSP >= vw) {
    return true;
  } else {
    return false;
  }
};

// ==================================================
//  Splide スライダー
// ==================================================
document.addEventListener("DOMContentLoaded", (event) => {
  const options = {
    type: "loop",
    drag: "free",
    focus: "center",
    perPage: 3,
    gap: 47,
    pagination: false,
    arrows: false,
    flickPower: 100,
    autoScroll: {
      speed: 0.5,
      pauseOnHover: false,
      pauseOnFocus: false,
    },
    mediaQuery: "max",
    fixedWidth: 204,
    fixedHeight: 210,
    breakpoints: {
      767: {
        gap: 33,
        fixedWidth: 145,
        fixedHeight: 150,
      },
    },
  };
  const mainvisualSlider = new Splide(".js-splide", options);
  mainvisualSlider.mount(window.splide.Extensions);
});
